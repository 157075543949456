import React from "react";

import { graphql, PageProps, useStaticQuery } from "gatsby";

import { Card, SEO, SiteLink } from "@parataxic/shared-ui";

import "@parataxic/shared-ui/src/styles/Card.css";

const SeriesView: React.FC<PageProps> = () => {
  const { allMdx } = useStaticQuery<Queries.ListerQuery>(graphql`
    query Lister {
      allMdx(
        filter: {
          frontmatter: {
            slug: { regex: "/^(?!/site.*).*/" }
            isDraft: { ne: true }
          }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              slug
              title
              subtitle
              createdYear: created(formatString: "YYYY")
            }
          }
        }
      }
    }
  `);
  return (
    <>
      <SEO
        title="Writing about programming, APIs, cloud services - devel.tech"
        description="Informative articles on python, tmux, typescript, aws and more"
      />
      <p style={{ textAlign: "center", width: "100%", paddingBottom: "1rem" }}>
        Hi there! I ported all this content from Django + reStructuredText to
        gatsby and markdown on March 20th, 2021.
      </p>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(330px, 1fr))", // 100% on mobile
          gridGap: "var(--space-6)",
          maxWidth: "1000px",
          margin: "0 auto",
          padding: "0rem 1rem 0 1rem",
          paddingInlineStart: "1rem",
          paddingInlineEnd: "1rem",
        }}
      >
        {allMdx &&
          allMdx.edges.map(({ node: post }, index) => (
            <Card style={{ padding: ".5rem" }} key={index}>
              <SiteLink href={post.frontmatter.slug}>
                {post.frontmatter.title}{" "}
                {post.frontmatter.createdYear && (
                  <>({post.frontmatter.createdYear})</>
                )}
              </SiteLink>
              <br />
              {post.frontmatter.subtitle && (
                <small>{post.frontmatter.subtitle}</small>
              )}{" "}
            </Card>
          ))}
      </div>
    </>
  );
};

export default SeriesView;
